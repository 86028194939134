import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'

import {
  IconeSetaAvancar,
  Botao,
  IconeSetaDuplaRetornar,
  FormUnform,
  IconeDoAvatarDoUsuario,
  FormRef,
  Carregando,
  AutenticacaoContainer,
  ComentarioTopicoArquivoInput,
  Tooltip,
  Informacao,
  Visao
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { Padding } from 'src/paginas/ipgs/split-pagamento/cadastro/styles'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Topico as TopicoType } from 'src/tipos/'

import { Comentario } from './comentario'
import { schema } from './schema'
import {
  Container,
  ContainerBotao,
  Conteudo,
  ImagemUsuario,
  InfoPrivado,
  ListaComentarios,
  TituloTopico
} from './styles'
import { TopicoProps } from './tipos'

const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const Topico: React.FC<TopicoProps> = ({
  onClick,
  idTopico,
  idGrupoDiscussao,
  comentarioSelecionadoId
}) => {
  const formRef = useRef<FormRef>(null)
  const [topico, definirTopico] = useState({} as TopicoType)
  const [carregandoTopico, definirCarregandoTopico] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const { usuario, visao } = AutenticacaoContainer.useContainer()
  const dica = {
    dicaId: '1',
    dicaInfo:
      'Suas dúvidas são privadas, somente você e o professor conseguem visualizar.'
  }

  const ehUsuarioCriador = useMemo(() => {
    return usuario.id === topico?.usuarioId
  }, [usuario, topico])

  const comentariosBloqueados = useMemo(() => {
    return topico?.comentariosBloqueados ?? false
  }, [topico])

  const carregarTopico = useCallback(async () => {
    try {
      definirCarregandoTopico(true)
      const resposta = await Api.RequisitarTopicoPorId(idTopico)
      const comentariosAlunos =
        resposta.comentarios?.filter(
          comentario => comentario.usuarioId === usuario.id
        ) ?? []

      if (
        visao === Visao.IPGS ||
        visao === Visao.Professor ||
        visao === Visao.Parceiro
      ) {
        definirTopico(resposta)
      } else {
        if (resposta.titulo === 'Como funciona?') {
          definirTopico(prevTopico => ({
            ...prevTopico,
            ...resposta,
            comentarios: resposta.comentarios ?? []
          }))
        } else {
          definirTopico(prevTopico => ({
            ...prevTopico,
            ...resposta,
            comentarios: comentariosAlunos
          }))
        }
      }
    } catch {
      definirTopico(null)
    } finally {
      definirCarregandoTopico(false)
    }
  }, [idTopico])

  const acaoSucesso = async (payload: {
    comentario: string
    privado: boolean
    arquivo?: File | string
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarComentario(
        idGrupoDiscussao,
        idTopico,
        sanitizeUtil(payload.comentario),
        true,
        payload.arquivo
      )

      formRef.current?.clearField('comentario')
      formRef.current?.clearField('arquivo')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    carregarTopico()
  }, [carregarTopico])

  return (
    <>
      <Container>
        <Botao
          elemento={<>{IconeSetaDuplaRetornar} Voltar para os tópicos</>}
          elementoInvertido
          tema="Link"
          onClick={onClick}
        />
        <Padding />
        <TituloTopico>
          <h3>Fórum de dúvidas</h3>
          {IconeSetaAvancar}
          <span>{topico.titulo}</span>
        </TituloTopico>
      </Container>
      {(ehUsuarioCriador || !comentariosBloqueados) && (
        <FormUnform ref={formRef} acaoSucesso={acaoSucesso} schema={schema}>
          <>
            <Conteudo>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img width="40" height="40" src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Conteudo>
            <ContainerBotao>
              <InfoPrivado data-tip={dica.dicaInfo} data-for={dica.dicaId}>
                <Informacao />
                <Tooltip id={dica.dicaId} place={'right'} html={true} />
              </InfoPrivado>
              <ComentarioTopicoArquivoInput id="arquivo" name="arquivo" />
              <Botao
                texto="Enviar Comentário"
                carregando={carregando}
                type="button"
                onClick={() => formRef?.current?.submitForm()}
              />
            </ContainerBotao>
          </>
        </FormUnform>
      )}
      <Padding />
      {carregandoTopico ? (
        <Carregando texto="Carregando comentários ..." />
      ) : (
        <ListaComentarios>
          {topico?.comentarios?.length > 0 && (
            <Comentario
              tipo="comentario"
              comentarios={topico?.comentarios}
              topico={idTopico}
              grupo={idGrupoDiscussao}
              carregarTopico={carregarTopico}
              comentarioDestacadoId={comentarioSelecionadoId}
              carregandoTopico={carregandoTopico}
            />
          )}
        </ListaComentarios>
      )}
    </>
  )
}
