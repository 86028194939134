/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { toast } from 'react-toastify'

import { useControleDeRequisicao } from 'src/compartilhados'
import {
  AutenticacaoContainer,
  SaudacoesDoMural,
  AgendaDoMural,
  TipoDaAgendaDoMural,
  OcultarFuncionalidade,
  useBarraSuperior,
  NotificacoesContainer,
  Notificacao
} from 'src/componentes'
import { RotasApp } from 'src/rotas'
import { Api } from 'src/servicos'
import { PainelMatriculasDoAluno } from 'src/tipos'

import { Cursos } from './components'
import { agendaMock } from './mocks'
import { Container, Conteudo } from './styles'

export const PaginaInicialAluno: React.FC = () => {
  const [novoUsuario, definirNovoUsuario] = useState<boolean>(false)
  const { usuario } = AutenticacaoContainer.useContainer()
  const barraSuperiora = useBarraSuperior()
  const notificacoes = NotificacoesContainer.useContainer()
  const requisicao = useControleDeRequisicao<PainelMatriculasDoAluno, string>()
  const [eventoDisparado, setEventoDisparado] = useState<boolean>(false)

  const adicionarNotificacao = (
    documentosPendentes: boolean,
    atualizacaoNotaTCC: boolean,
    existeDuvidaRespondia: boolean,
    existeDocumentosEmAnalise: boolean,
    existeDocumentosAceitos: boolean,
    existeDocumentosRecusados: boolean,
    url: string
  ): void => {
    const novasNotificacoes: Notificacao[] = []

    if (documentosPendentes) {
      novasNotificacoes.push({
        titulo: 'Documentos pendentes',
        data: new Date(),
        descricao: 'Você possui documentos pendentes.',
        lida: false,
        url: `${RotasApp.Aluno}/meus-documentos/brasileiro`
      } as Notificacao)
    }

    if (atualizacaoNotaTCC) {
      novasNotificacoes.push({
        titulo: 'Atualização nota TCC',
        data: new Date(),
        descricao: 'Você possui atualizações na nota do TCC.',
        lida: false
      } as Notificacao)
    }

    if (existeDuvidaRespondia) {
      novasNotificacoes.push({
        titulo: 'Dúvida respondida',
        data: new Date(),
        descricao: 'Uma dúvida sua foi respondida.',
        lida: false,
        url: url
      })
    }

    if (existeDocumentosEmAnalise) {
      novasNotificacoes.push({
        titulo: 'Documento em análise',
        data: new Date(),
        descricao: 'Você possui documentos em análise.',
        lida: false,
        url: `${RotasApp.Aluno}/meus-documentos/brasileiro`
      })
    }

    if (existeDocumentosAceitos) {
      novasNotificacoes.push({
        titulo: 'Documento aceito',
        data: new Date(),
        descricao: 'Seus documentos foram aprovados!',
        lida: false,
        url: `${RotasApp.Aluno}/meus-documentos/brasileiro`
      })
    }

    if (existeDocumentosRecusados) {
      novasNotificacoes.push({
        titulo: 'Documento recusado',
        data: new Date(),
        descricao: 'Seu documento foi recusado. Clique aqui para reenviar.',
        lida: false,
        url: `${RotasApp.Aluno}/meus-documentos/brasileiro`
      })
    }
    notificacoes.atualizar(novasNotificacoes)
  }

  const ObterTicketsUsuario = async (id: string) => {
    const tickets = await Api.ObterTicketPorUsuarioId(
      id,
      'Solicitacao',
      'ForumDuvidas'
    )

    const ticket =
      Array.isArray(tickets) && tickets.length > 0 ? tickets[0] : null

    if (
      ticket &&
      ticket.linkDuvida &&
      ticket.topicoDuvida &&
      ticket.comentarioId
    ) {
      const url = `${RotasApp.SalaAula}/outros-cursos/${ticket.linkDuvida}?target=grupo-discussao#topico-${ticket.topicoDuvida}-comentario-${ticket.comentarioId}`
      return url
    } else {
      return null
    }
  }

  const obterPainelInicialDoAluno = async () => {
    try {
      requisicao.carregador.alterarParaIniciado()
      const resposta = await Api.ObterPainelInicialDoAluno(usuario.id)
      requisicao.resposta.adicionarResposta({
        matriculas: resposta.painelAluno.matriculas,
        carrinhosCompra: resposta.painelAluno.carrinhosCompra
      })
      const notificacoes = await Api.ObterNotificacoesAluno(usuario.id)

      const matriculas = resposta.painelAluno.matriculas
      const hoje = new Date().toISOString().split('T')[0]
      const matriculouHoje = matriculas.some(
        matricula => matricula.dataCriacao.split('T')[0] === hoje
      )
      definirNovoUsuario(matriculouHoje)

      let url

      if (resposta.existeDuvidaRespondida) {
        url = await ObterTicketsUsuario(usuario.id)
      }
      adicionarNotificacao(
        notificacoes.existeDocumentoPendente,
        notificacoes.existeAtualizacaoNotaTCC,
        notificacoes.existeDuvidaRespondida,
        notificacoes.existeDocumentosEmAnalise,
        notificacoes.existeDocumentosAceitos,
        notificacoes.existeDocumentosRecusados,
        url
      )
    } catch (erro) {
      requisicao.erros.adicionarErro(erro)
    } finally {
      requisicao.carregador.alterarParaConcluido()
    }
  }

  const obterFotoUsuario = async () => {
    try {
      const foto = await Api.ObterFotoUsuario(usuario.id)
      if (foto) {
        barraSuperiora.atualizarFoto(foto)
      }
    } catch (erro) {
      toast('Erro ao carregar foto do usuário', { type: 'info' })
    }
  }

  const configDaAgenda = {
    eventos: agendaMock,
    configuracao: {
      tipo: TipoDaAgendaDoMural.Compacto
    }
  }

  useEffect(() => {
    obterPainelInicialDoAluno()
  }, [])

  useEffect(() => {
    obterFotoUsuario()
  }, [])

  useEffect(() => {
    const eventoArmazenado = localStorage.getItem('@ipgs/eventoDisparado')

    if (eventoArmazenado === 'true') {
      setEventoDisparado(true)
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_GTM && novoUsuario && !eventoDisparado) {
      const tagManagerArgs = {
        gtmId: 'GTM-KF5XH378',
        dataLayer: { event: 'pageview_aluno' }
      }
      TagManager.initialize(tagManagerArgs)

      localStorage.setItem('@ipgs/eventoDisparado', 'true')
      setEventoDisparado(true)
    }
  }, [obterPainelInicialDoAluno])

  return (
    <Container>
      <SaudacoesDoMural usuario={usuario} />
      <Conteudo>
        <Cursos
          controleDeRequisicao={requisicao}
          acaoAtualizacao={obterPainelInicialDoAluno}
        />
        <OcultarFuncionalidade>
          <AgendaDoMural {...configDaAgenda} />
        </OcultarFuncionalidade>
      </Conteudo>
    </Container>
  )
}
