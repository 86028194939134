import { RouteComponentProps } from 'react-router-dom'

import { Entidade } from 'src/compartilhados'
import {
  EnderecoComPaisResidencia,
  Escolaridade,
  EstadoCivil,
  ModalidadeCurso,
  Raca,
  Sexo,
  UsuarioSituacao,
  TipoMatricula,
  TipoProrrogacao,
  OpcaoTCC,
  StatusFinanceiro
} from 'src/tipos'

export enum SituacaoMatricula {
  Reservado,
  Inativo,
  Matriculado,
  ContratoRescindido,
  Cancelado,
  Transferido,
  Finalizado,
  Reprovado,
  AguardandoCartaoPagamentoRecorrente,
  Recuperacao
}

export interface PaginaVisualizarAlunoProps
  extends RouteComponentProps<{
    id?: string
  }> {
  id?: string
}

export enum SituacaoFinanceiraExtenso {
  Reservado = 'Reservado',
  Inativo = 'Inativo',
  Matriculado = 'Matriculado',
  ContratoRescindido = 'ContratoRescindido',
  Cancelado = 'Cancelado',
  Transferido = 'Transferido',
  Finalizado = 'Finalizado',
  Reprovado = 'Reprovado',
  AguardandoCartaoPagamentoRecorrente = 'AguardandoCartaoPagamentoRecorrente',
  Recuperacao = 'Recuperacao'
}

export enum TipoProrrogacaoPorExtenso {
  Padrao = 'Padrão',
  Licenca = 'Licença',
  Recuperacao = 'Recuperação'
}

export interface ProrrogacaoMatriculaAlunoDto {
  quantidadeMeses: number
  dataCriacao: string
  tipoProrrogacao: TipoProrrogacao
}

export interface MatriculaAluno {
  id: string
  matriculaId: string
  nomeCurso: string
  nomeTurma: string
  modalidade: ModalidadeCurso
  situacaoFinanceira: SituacaoMatricula
  ipAceiteTermos: string
  dataHoraAceiteTermos: string
  dataUltimoAcesso?: string
  tipoMatricula: TipoMatricula
  codigoTurma?: string
  dataFimTurma?: string
  dataFimProrrogacao?: string
  dataInicio?: string
  situacaoMatricula: SituacaoMatricula
  statusFinanceiro: StatusFinanceiro
  cursoId: string
  turmaId?: string
  nota?: number
  cargaHoraria?: number
  possuiProrrogacao: boolean
  prorrogacoes?: ProrrogacaoMatriculaAlunoDto[]
  possuiTransferencia: boolean
  turmaDestino: string
  transferidoPor: string
  opcaoTCC?: OpcaoTCC
  dataOpcaoTCC?: string
  professorOrientador: string
  professorBanca: string
  tituloTCC: string
  podeSolicitarProrrogacaoLicenca: boolean
  dataNascimentoProrrogacaoLicenca?: string
  tempoProrrogacaoLicenca?: number
  dataHoraAlteracaoTCC?: string
  dataReprovacao?: string
  dataAprovacao?: string
}

export interface Aluno extends Entidade<string> {
  cpf: string
  documentoIdentidade: string
  codigoConselho: string
  nacionalidade: string
  orgaoEmissor: string
  nome: string
  nomeSocial: string
  dataNascimento: Date
  sexo: Sexo
  generoOutros?: string
  estadoCivil: EstadoCivil
  profissao: string
  profissaoOutros: string
  escolaridade: Escolaridade
  mesAnoColacaoGrau: Date
  telefone: string
  celular: string
  email: string
  corRaca: Raca
  foto: string
  endereco: EnderecoComPaisResidencia
  matriculaCurso?: MatriculaAluno[]
  matriculaTurma?: MatriculaAluno[]
  situacao?: UsuarioSituacao
  dataUltimoAcesso?: string
}

export interface MatriculaEditada {
  matriculaId: string
  situacao: SituacaoMatricula
}

export interface AlunoEditado {
  situacao: string
  matriculas: MatriculaEditada[]
}

export enum TipoMatriculaPorExtenso {
  Normal = 'Normal',
  Ouvinte = 'Ouvinte',
  Reingresso = 'Reingresso',
  Transferencia = 'Transferencia',
  TrocaDeCurso = 'TrocaDeCurso',
  ColaboradorIPGS = 'ColaboradorIPGS',
  Cancelado7Dias = 'Cancelado7Dias',
  ErroMatricula = 'ErroMatricula'
}

export enum SituacaoFinanceiraPorExtenso {
  Reservado = 'Reservado',
  Inativo = 'Inativo',
  Matriculado = 'Matriculado',
  ContratoRescindido = 'Contrato Rescindido',
  Cancelado = 'Cancelado',
  Transferido = 'Transferido',
  Finalizado = 'Finalizado',
  Reprovado = 'Reprovado',
  AguardandoCartaoPagamentoRecorrente = 'Aguardando Cartão Pagamento Recorrente',
  Recuperacao = 'Recuperação'
}

export interface ContratosAdendo {
  id: string
  nome: string
}

export interface ResumoContratos {
  matriculaId: string
  adendos: ContratosAdendo[]
}
