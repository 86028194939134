import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  ModalidadeCursoPorExtenso,
  SituacaoFinanceira,
  SituacaoFinanceiraPorExtenso,
  SituacaoResponsavelFinanceiroPorExtenso,
  SituacaoTransferencia,
  StatusFinanceiro,
  StatusFinanceiroPorExtenso,
  TipoRecorrencia,
  TipoRecorrenciaAcaoPorExtensoSimples
} from 'src/tipos'

import { formatadores } from '../pagina-financeiro/formatadores'
import {
  Container,
  ContainerLink,
  TextoSituacaoFinanceira,
  TextoSituacaoResponsavelFinanceiro
} from './styles'
export interface ItemCursoProps {
  id: string
  className?: string
  modalidade: string
  nome: string
  link: string
  valorTotal: number
  saldo: number
  situacaoFinanceira: string
  statusFinanceiro: string
  valorTotalTCC?: number
  saldoDevedorTCC?: number
  nomeTurma?: string
  transferenciaOrigem?: boolean
  transferenciaDestino?: boolean
  situacaoTransferencia?: SituacaoTransferencia
  nomeAluno?: string
  ehResponsavel?: boolean
  situacaoResponsavelFinanceiro?: SituacaoResponsavelFinanceiroPorExtenso
  tipoRecorrencia?: TipoRecorrencia
  tipoSoCertificacao?: boolean
}

export const ItemCurso: React.FC<ItemCursoProps> = ({
  className,
  link,
  nome,
  valorTotal,
  saldo,
  modalidade,
  situacaoFinanceira,
  statusFinanceiro,
  valorTotalTCC,
  saldoDevedorTCC,
  nomeTurma,
  transferenciaOrigem,
  transferenciaDestino,
  situacaoTransferencia,
  nomeAluno,
  ehResponsavel,
  situacaoResponsavelFinanceiro,
  tipoRecorrencia,
  tipoSoCertificacao
}) => {
  modalidade = ModalidadeCursoPorExtenso[modalidade]

  const situacoesResponsavelSemAcesso =
    situacaoResponsavelFinanceiro ===
      SituacaoResponsavelFinanceiroPorExtenso.RecusadoAutomaticamente ||
    situacaoResponsavelFinanceiro ===
      SituacaoResponsavelFinanceiroPorExtenso.AguardandoFinalizacao

  const situacaoResponsavelEmailEnviado =
    situacaoResponsavelFinanceiro ===
    SituacaoResponsavelFinanceiroPorExtenso.EmailEnviado

  const transferenciaCancelada = useMemo(() => {
    return (
      transferenciaDestino === true &&
      situacaoTransferencia === SituacaoTransferencia.Cancelada
    )
  }, [transferenciaDestino, situacaoTransferencia, situacaoFinanceira])

  const mensagemSituacaoFinanceira = useMemo(() => {
    if (transferenciaCancelada) {
      return 'Transferência Cancelada'
    }

    if (transferenciaOrigem) {
      return `${SituacaoFinanceiraPorExtenso[situacaoFinanceira]} - Troca de Curso`
    }

    if (
      SituacaoFinanceira[situacaoFinanceira] ===
        SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente &&
      tipoRecorrencia
    ) {
      return `${SituacaoFinanceiraPorExtenso[situacaoFinanceira]} - ${TipoRecorrenciaAcaoPorExtensoSimples[tipoRecorrencia]}`
    }

    if (
      StatusFinanceiro[statusFinanceiro] ===
        StatusFinanceiro.AguardandoCartaoPagamentoRecorrente &&
      tipoRecorrencia
    ) {
      return `${StatusFinanceiroPorExtenso[statusFinanceiro]} - ${TipoRecorrenciaAcaoPorExtensoSimples[tipoRecorrencia]}`
    }

    return SituacaoFinanceiraPorExtenso[situacaoFinanceira]
  }, [transferenciaDestino, situacaoFinanceira, situacaoFinanceira])

  const exibirVerParcelas = useMemo(() => {
    if (transferenciaCancelada) {
      return false
    }

    if (situacoesResponsavelSemAcesso || situacaoResponsavelEmailEnviado) {
      return false
    }

    return (
      Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.Inativo
    )
  }, [
    transferenciaCancelada,
    situacaoFinanceira,
    situacaoResponsavelFinanceiro
  ])

  const situacoesResponsavelFinanceiro = (
    situacao: SituacaoResponsavelFinanceiroPorExtenso
  ): string => {
    switch (situacao) {
      case SituacaoResponsavelFinanceiroPorExtenso.AguardandoFinalizacao:
        return 'Aguardando Finalização'
      case SituacaoResponsavelFinanceiroPorExtenso.RecusadoAutomaticamente:
        return 'Recusado Automaticamente'
      default:
        return SituacaoResponsavelFinanceiroPorExtenso[situacao]
    }
  }

  return (
    <Container className={className}>
      <div>
        <h4>{modalidade}</h4>
        <h5>{nome}</h5>
        {nomeTurma && !ehResponsavel && (
          <>
            <h6
              style={{ color: '#FF156B', fontWeight: 500, marginBottom: '4px' }}
            >
              Turma
            </h6>
            <h6 style={{ marginBottom: '18px' }}>{nomeTurma}</h6>
          </>
        )}
        {ehResponsavel && (
          <>
            <h6
              style={{ color: '#FF156B', fontWeight: 500, marginBottom: '4px' }}
            >
              Aluno
            </h6>
            <h6 style={{ marginBottom: '18px' }}>{nomeAluno}</h6>
          </>
        )}
        <p>
          <b>Valor total do curso:</b> {formatadores.moeda(valorTotal)}
        </p>
        <p>
          <b>Saldo devedor: </b>
          {formatadores.moeda(saldo)}
        </p>
        {modalidade !== ModalidadeCursoPorExtenso.Livre && (
          <>
            <p>
              <b>Valor total do TCC:</b> {formatadores.moeda(valorTotalTCC)}
            </p>
            <p>
              <b>Saldo devedor TCC: </b>
              {formatadores.moeda(saldoDevedorTCC)}
            </p>
          </>
        )}
        <TextoSituacaoFinanceira>
          {mensagemSituacaoFinanceira}
        </TextoSituacaoFinanceira>
        <ContainerLink>
          {exibirVerParcelas && <Link to={link}>Ver Parcelas</Link>}
        </ContainerLink>
        <ContainerLink>
          {situacaoResponsavelEmailEnviado && (
            <Link to={link}>Entre para dar o Aceite</Link>
          )}
        </ContainerLink>
        {situacoesResponsavelSemAcesso && (
          <TextoSituacaoResponsavelFinanceiro>
            {situacoesResponsavelFinanceiro(
              SituacaoResponsavelFinanceiroPorExtenso[
                situacaoResponsavelFinanceiro
              ]
            )}
          </TextoSituacaoResponsavelFinanceiro>
        )}
      </div>
    </Container>
  )
}
