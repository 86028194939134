import React, { useRef, useState } from 'react'

import classNames from 'classnames'

import { Abas } from '../abas'
import { IconeSetaDropDown, IconeSetaVertical } from '../icones'
import { Cores } from '../styles/cores'
import { BotaoExpandir, ContainerExpansao, ContainerBotao } from './styles'
import { TabelaLinhaProps } from './tipos'

const Linha: React.FC<TabelaLinhaProps> = ({
  Indice,
  Colunas,
  AbasTabela,
  Dados,
  TemaLinha
}) => {
  const [expandido, definirExpandido] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const estiloLinha = TemaLinha ? { color: Cores[TemaLinha] } : {}
  const expandirLinha = (expandido: boolean) => {
    definirExpandido(expandido)

    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  return (
    <>
      <tr
        className={classNames({
          par: (Indice + 1) % 2 === 0
        })}
        style={estiloLinha}
      >
        <td key={`linha-${Indice}-expansao`}>
          <div>
            <ContainerBotao>
              <BotaoExpandir
                onClick={() => expandirLinha(!expandido)}
                type="button"
              >
                {expandido ? IconeSetaVertical : IconeSetaDropDown}
              </BotaoExpandir>
            </ContainerBotao>
          </div>
        </td>

        {Colunas.map(
          (
            { Chave, Renderizar, Alinhamento, StyleColuna },
            indiceColuna: number
          ) => (
            <td
              key={`linha-${Indice}-coluna-${indiceColuna}`}
              align={Alinhamento || 'left'}
              style={StyleColuna}
            >
              {Renderizar ? (
                Renderizar(Dados)
              ) : (
                <span className={`${Chave}-${Indice}`}>{Dados[Chave]}</span>
              )}
            </td>
          )
        )}
      </tr>
      <td colSpan={7}>
        <ContainerExpansao expandido={expandido} id="tabela-dinamica">
          <Abas paginas={AbasTabela} dados={Dados} />
        </ContainerExpansao>
      </td>
    </>
  )
}

export default Linha
