import React, { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useControladorDeConteudos } from 'src/compartilhados'
import {
  AutenticacaoContainer,
  IconeBook,
  Spacer,
  Visao
} from 'src/componentes'
import { RotasAluno } from 'src/rotas/aluno'
import {
  PainelFinanceiro,
  DadosFinanceirosDoAluno,
  DadosFinanceirosCarrinhoCompraAluno,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'

import {
  ContainerCursos,
  ListaCursos,
  SubTitulo,
  SubTituloContainer,
  SubTituloIcone,
  ListaCarrinhoCompra
} from '../styles'
import { ItemCarrinhoCompra } from './item-carrinho-compra'
import { ItemCurso } from './item-curso'
import {
  LinkRodapeComEstilos,
  LinkRodapeContainer,
  OcorrenciasNaoEncontradas
} from './styles'

export interface CenarioFinanceiroProps {
  situacao: PainelFinanceiro
  ehResponsavel?: boolean
}

const configuracaoDeConteudos = {
  disposicaoInicial: 3,
  passos: 3
}

export const CenarioFinanceiro: FC<CenarioFinanceiroProps> = ({
  situacao,
  ehResponsavel
}) => {
  const { visao } = AutenticacaoContainer.useContainer()
  const { usuarioId } = useParams<{ usuarioId?: string }>()

  const visaoIPGS = useMemo(() => visao === Visao.IPGS, [visao])

  const controlador = useControladorDeConteudos<DadosFinanceirosDoAluno>(
    situacao.dadosFinanceiros,
    configuracaoDeConteudos
  )

  const controladorCarrinho = useControladorDeConteudos<DadosFinanceirosCarrinhoCompraAluno>(
    situacao.dadosFinanceirosCarrinhoCompra,
    configuracaoDeConteudos
  )

  const listarOcorrenciasFinanceiras = (): JSX.Element => {
    const mapper = (ocorrencia: DadosFinanceirosDoAluno): JSX.Element => {
      const { matriculaId, modalidadeCurso, nomeCurso } = ocorrencia

      let url = validaEhModalidadeLivreOuEvento(ocorrencia.modalidadeCurso)
        ? `${RotasAluno.FinanceiroCursoLivre}/${matriculaId}/${modalidadeCurso}/${nomeCurso}`
        : `${RotasAluno.FinanceiroCursoOutro}/${matriculaId}/${modalidadeCurso}/${nomeCurso}`

      if (visaoIPGS) {
        url = validaEhModalidadeLivreOuEvento(ocorrencia.modalidadeCurso)
          ? `${RotasAluno.FinanceiroCursoLivre}/${matriculaId}/${modalidadeCurso}/${nomeCurso}/${usuarioId}`
          : `${RotasAluno.FinanceiroCursoOutro}/${matriculaId}/${modalidadeCurso}/${nomeCurso}`
      }
      if (ocorrencia.tipoSoCertificacao === true) {
        return <></>
      } else {
        return (
          <ItemCurso
            id={ocorrencia.matriculaId}
            key={ocorrencia.matriculaId}
            modalidade={ocorrencia.modalidadeCurso}
            nome={ocorrencia.nomeCurso}
            link={url}
            valorTotal={ocorrencia.valorTotalCurso}
            saldo={ocorrencia.saldoDevedor}
            situacaoFinanceira={ocorrencia.situacaoFinanceira}
            statusFinanceiro={ocorrencia.statusFinanceiro}
            valorTotalTCC={ocorrencia.valorTotalTCC}
            saldoDevedorTCC={ocorrencia.saldoDevedorTCC}
            nomeTurma={ocorrencia.nomeTurma}
            transferenciaOrigem={ocorrencia.transferenciaOrigem}
            transferenciaDestino={ocorrencia.transferenciaDestino}
            situacaoTransferencia={ocorrencia.situacaoTransferencia}
            nomeAluno={ocorrencia?.nomeAluno}
            ehResponsavel={ehResponsavel}
            situacaoResponsavelFinanceiro={
              ocorrencia.situacaoResponsavelFinanceiro
            }
            tipoRecorrencia={ocorrencia.tipoRecorrencia}
            tipoSoCertificacao={ocorrencia.tipoSoCertificacao}
          />
        )
      }
    }

    const children = controlador.exibir().map(mapper)
    return <ListaCursos children={children} />
  }

  const listarOcorrenciasCarrinhoCompra = (): JSX.Element => {
    const mapper = (
      carrinho: DadosFinanceirosCarrinhoCompraAluno
    ): JSX.Element => {
      const url = `${RotasAluno.FinanceiroCursoLivre}/carrinho-compra/${carrinho.carrinhoCompraCursoLivreId}`
      return (
        <ItemCarrinhoCompra
          id={carrinho.carrinhoCompraCursoLivreId}
          key={carrinho.carrinhoCompraCursoLivreId}
          cursosNomes={carrinho.cursosNomes}
          link={url}
          valorTotal={carrinho.valorTotal}
          saldoDevedor={carrinho.saldoDevedor}
          status={carrinho.statusCarrinhoCompra}
        />
      )
    }

    const children = controladorCarrinho.exibir().map(mapper)
    return <ListaCarrinhoCompra children={children} />
  }

  const botaoParaObterMaisOcorrencias = (): JSX.Element => {
    if (controlador.concluido()) return null

    const execucao = () => controlador.proximo()

    return (
      <LinkRodapeContainer>
        <LinkRodapeComEstilos acao={execucao} texto="Ver mais" />
      </LinkRodapeContainer>
    )
  }

  const botaoParaObterMaisOcorrenciasCarrinho = (): JSX.Element => {
    if (controladorCarrinho.concluido()) return null

    const execucao = () => controladorCarrinho.proximo()

    return (
      <LinkRodapeContainer>
        <LinkRodapeComEstilos acao={execucao} texto="Ver mais" />
      </LinkRodapeContainer>
    )
  }

  useEffect(() => {
    controlador.atualizar(
      situacao.dadosFinanceiros,
      configuracaoDeConteudos.passos
    )
  }, [situacao])

  useEffect(() => {
    controladorCarrinho.atualizar(
      situacao.dadosFinanceirosCarrinhoCompra,
      configuracaoDeConteudos.passos
    )
  }, [situacao])

  if (
    situacao &&
    situacao.dadosFinanceiros?.length === 0 &&
    situacao.dadosFinanceirosCarrinhoCompra?.length === 0
  ) {
    return (
      <ContainerCursos>
        <OcorrenciasNaoEncontradas>
          Ainda não existe ocorrência financeira para o aluno.
        </OcorrenciasNaoEncontradas>
      </ContainerCursos>
    )
  }

  return (
    <ContainerCursos>
      {situacao.dadosFinanceirosCarrinhoCompra?.length > 0 && (
        <>
          <SubTituloContainer>
            <SubTituloIcone>{IconeBook}</SubTituloIcone>
            <SubTitulo>Pedidos</SubTitulo>
          </SubTituloContainer>
          {listarOcorrenciasCarrinhoCompra()}
          {botaoParaObterMaisOcorrenciasCarrinho()}
          <Spacer padding="20px 0px" />
        </>
      )}
      {situacao.dadosFinanceiros?.length > 0 && (
        <>
          <SubTituloContainer>
            <SubTituloIcone>{IconeBook}</SubTituloIcone>
            <SubTitulo>
              {ehResponsavel ? 'Matrículas sob responsabilidade' : 'Cursos'}
            </SubTitulo>
          </SubTituloContainer>
          {listarOcorrenciasFinanceiras()}
          {botaoParaObterMaisOcorrencias()}
        </>
      )}
    </ContainerCursos>
  )
}
