import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  SelectOpcao,
  TelefoneInputUnform,
  Tooltip,
  useResponsivoState
} from 'src/componentes'
import { Botao } from 'src/componentes/botao'
import { Breadcrumb } from 'src/componentes/breadcrumb'
import { Cabecalho } from 'src/componentes/cabecalho'
import { focarPrimeiroCampoComErro } from 'src/componentes/funcoes'
import { IconeBack } from 'src/componentes/icones'
import { CampoLabel } from 'src/componentes/input/styles'
import { Link } from 'src/componentes/link'
import { Modal } from 'src/componentes/modal'
import { ModalRef } from 'src/componentes/modal/tipos'
import { Navegacao } from 'src/componentes/navegacao'
import { OpcaoSelect } from 'src/componentes/select-async/tipos'
import { SubTitulo } from 'src/componentes/subtitulo'
import { CheckboxUnform } from 'src/componentes/unform/checkbox'
import { FormUnform } from 'src/componentes/unform/form'
import { InputUnform } from 'src/componentes/unform/input'
import { CpfInputUnform } from 'src/componentes/unform/input/cpf'
import { SelectAsyncUnform, SelectUnform } from 'src/componentes/unform/select'
import { SeletorUnform } from 'src/componentes/unform/seletor'
import { RotasSistema } from 'src/rotas'
import { Api } from 'src/servicos'
import { NotificarUsuario } from 'src/servicos/api'
import {
  EditarUsuario,
  InserirUsuario,
  ObterIdUsuario
} from 'src/servicos/api/usuario'
import {
  ModoPaginaCadastro,
  TurmaParceiro,
  Usuario,
  UsuarioPerfil,
  UsuarioSituacao
} from 'src/tipos'

import { schema, schemaEdicao } from './schema'
import {
  Container,
  ContainerAcoes,
  ContainerDados,
  ContainerDadosBotao,
  ContainerDadosMedio,
  ContainerDadosPequeno,
  ContainerDadosTelefone,
  ContainerLateral,
  ContainerLink,
  ContainerMain,
  Conteudo,
  Opcao,
  OpcaoDados,
  Secao
} from './styles'
import { PaginaUsuarioProps } from './tipos'

interface Dados extends Usuario {
  possuiNomeSocial?: boolean
  repetirSenha?: string
}

enum Navegacoes {
  DadosPessoais = 'dados-pessoais',
  Contato = 'contato',
  DadosAcesso = 'dados-acesso',
  TurmasParceiro = 'turmas-parceiro'
}

const PaginaUsuario: React.FC<PaginaUsuarioProps> = ({ match, modo }) => {
  const [titulo] = useState(
    `${modo === ModoPaginaCadastro.Inclusao ? 'Inclusão' : 'Edição'} de Usuário`
  )
  const [pronto, definirPronto] = useState(false)
  const [possuiNomeSocial, definirPossuiNomeSocial] = useState(false)
  const [carregando, definirCarregando] = useState(false)
  const [usuario, definirUsuario] = useState<Usuario | undefined>(undefined)
  const [perfis, definirPerfis] = useState<UsuarioPerfil[]>([])
  const modalRef = useRef<ModalRef>(null)
  const history = useHistory()
  const [botaoTokenBloqueado, definirBotaoTokenBloqueado] = useState(false)

  const { mobile } = useResponsivoState()

  const usuarioDefault = {
    situacao: UsuarioSituacao.Ativo,
    turmasParceiro: []
  } as Usuario

  const dadosIniciais = useMemo(() => {
    return usuario
      ? {
          ...usuario,
          possuiNomeSocial: !!usuario.nomeSocial,
          repetirSenha: usuario.senha,
          turmasParceiro: usuario.turmasParceiro?.map(x => x.turmaId) ?? []
        }
      : { ...usuarioDefault }
  }, [usuario])

  const carregarUsuario = async () => {
    try {
      const id = match.params.id
      if (id) {
        const novoUsuario = await Api.RequisitarUsuario(id)

        definirPossuiNomeSocial(!!novoUsuario.nomeSocial)
        definirUsuario({
          ...novoUsuario,
          situacao: UsuarioSituacao[novoUsuario.situacao]
        })

        const perfisUsuario =
          novoUsuario?.perfis?.map(x => UsuarioPerfil[x]) ?? []
        definirPerfis(perfisUsuario)
      }
    } catch (error) {
      toast('Houve um problema ao obter os dados do usuário', {
        type: 'error'
      })
    } finally {
      definirPronto(true)
    }
  }

  const possuiPerfilParceiro = useMemo(
    () => perfis.some(x => x === UsuarioPerfil.Parceiro),
    [perfis]
  )

  const atualizarPerfisEdicao = (opcoes: SelectOpcao[]) => {
    if (opcoes?.length <= 0) {
      definirPerfis([])
    } else {
      const perfisSelecionados = opcoes.map(x => UsuarioPerfil[x.id])
      definirPerfis(perfisSelecionados)
    }
  }

  const carregarOpcoesPorTexto = async (
    texto: string
  ): Promise<Array<OpcaoSelect>> => {
    const resposta = await Api.RequisitarListaTurmas(texto)

    return resposta.registros.map(r => ({
      id: r.id,
      texto: `${r.codigo} - ${r.nome}`,
      dadosAdicionais: r
    }))
  }

  const carregarOpcoes = async (
    ids: string | string[]
  ): Promise<OpcaoSelect[]> => {
    try {
      const itens = []
      if (ids?.length > 0) {
        const idsSelecionados = Array.isArray(ids) ? [...ids] : [ids]
        idsSelecionados.forEach(async element => {
          const resposta = await Api.RequisitarTurmaPorId(element)
          if (resposta) {
            itens.push({
              id: resposta.id,
              texto: `${resposta.codigo} - ${resposta.nome}`,
              dadosAdicionais: resposta
            })
          }
        })
      }

      return itens
    } catch {
      return []
    }
  }

  useEffect(() => {
    carregarUsuario()
  }, [])

  const acaoSucesso = async (dados: Dados) => {
    definirCarregando(true)
    try {
      if (modo === ModoPaginaCadastro.Inclusao) {
        const token = await InserirUsuario({
          ...dados,
          turmasParceiro: possuiPerfilParceiro
            ? dados.turmasParceiro.map(x => {
                return ({
                  turmaId: x,
                  usuarioId: dados.id
                } as unknown) as TurmaParceiro
              })
            : []
        })
        const idUsuario = await ObterIdUsuario(token.data)
        toast('Usuário cadastrado com sucesso', {
          type: 'success'
        })

        history.push(RotasSistema.Usuarios)
        await NotificarUsuario(idUsuario)
      } else {
        await EditarUsuario(match.params.id, {
          ...dados,
          perfis: dados.perfis,
          turmasParceiro: possuiPerfilParceiro
            ? dados.turmasParceiro.map(x => {
                return ({
                  turmaId: x,
                  usuarioId: dados.id
                } as unknown) as TurmaParceiro
              })
            : []
        })
        toast('Usuário editado com sucesso', {
          type: 'success'
        })

        history.push(RotasSistema.Usuarios)
      }
    } catch (e: any) {
      e?.response?.data?.errors.forEach(erro => {
        toast(erro, { type: 'error' })
      })
    } finally {
      definirCarregando(false)
    }
  }

  const renovarToken = async () => {
    definirBotaoTokenBloqueado(true)
    try {
      await Api.RenovarToken(usuario?.id)
      toast('Token reenviado com sucesso!', { type: 'success' })
    } catch (error) {
      toast('Erro', { type: 'error' })
    }
  }

  return (
    <>
      {pronto ? (
        <Container>
          <ContainerMain>
            <ContainerLink>
              <Link
                texto="Voltar"
                href={RotasSistema.Usuarios}
                icone={IconeBack}
              />
            </ContainerLink>
            <Cabecalho>
              <Breadcrumb
                titulo={titulo}
                atalhos={[
                  {
                    texto: 'Sistema'
                  },
                  {
                    texto: 'Usuários',
                    acao: () => history.goBack()
                  },
                  {
                    texto: titulo
                  }
                ]}
              />
            </Cabecalho>
            <Conteudo>
              <FormUnform
                schema={
                  modo === ModoPaginaCadastro.Inclusao ? schema : schemaEdicao
                }
                dadosIniciais={dadosIniciais}
                acaoSucesso={acaoSucesso}
                acaoFalha={focarPrimeiroCampoComErro}
              >
                <>
                  <Secao id={Navegacoes.DadosPessoais}>
                    <SubTitulo texto="Dados Pessoais" />
                    <ContainerDados>
                      <ContainerDadosPequeno>
                        <CpfInputUnform
                          name="cpf"
                          label="CPF"
                          disabled={modo === ModoPaginaCadastro.Edicao}
                          obrigatorio
                        />
                      </ContainerDadosPequeno>
                      <ContainerDadosPequeno>
                        <SeletorUnform<number>
                          name="situacao"
                          tipo="radio"
                          label="Situação"
                          seletores={[
                            {
                              id: UsuarioSituacao.Ativo,
                              texto: 'Ativo'
                            },
                            {
                              id: UsuarioSituacao.Inativo,
                              texto: 'Inativo'
                            }
                          ]}
                        />
                      </ContainerDadosPequeno>
                    </ContainerDados>
                    <InputUnform
                      name="nome"
                      label="Nome Completo (Atenção: Nome que será utilizado na emissão de certificados, diplomas e demais documentos oficiais da Instituição)"
                      maxLength={200}
                      obrigatorio
                      style={{ textTransform: 'uppercase' }}
                    />
                    <Opcao>
                      <CampoLabel>Possui nome social?</CampoLabel>
                      <OpcaoDados>
                        <CheckboxUnform
                          id="chk_possui_nome_social"
                          name="possuiNomeSocial"
                          onChange={() => definirPossuiNomeSocial(old => !old)}
                          checked={possuiNomeSocial}
                        />
                        <p>
                          <b>Sim</b>{' '}
                          <i>
                            (Nome social é a adoção de nome diferente do
                            oficialmente registrado, de modo a identificar
                            adequadamente sua identidade de gênero - Decreto nº
                            8727/2016)
                          </i>
                        </p>
                      </OpcaoDados>
                    </Opcao>
                    {possuiNomeSocial && (
                      <InputUnform
                        name="nomeSocial"
                        label="Nome Social"
                        maxLength={200}
                        obrigatorio
                        style={{ textTransform: 'uppercase' }}
                      />
                    )}
                  </Secao>
                  <Secao id={Navegacoes.Contato}>
                    <SubTitulo texto="Contato" />
                    <ContainerDados>
                      <ContainerDadosMedio>
                        <InputUnform
                          type="email"
                          name="email"
                          label="E-mail"
                          maxLength={100}
                          obrigatorio
                        />
                      </ContainerDadosMedio>
                      <ContainerDadosTelefone>
                        <TelefoneInputUnform
                          id="ipt_celular"
                          name="celular"
                          label="Celular"
                          obrigatorio
                          maxWidthInput={mobile ? '100%' : '65%'}
                        />
                      </ContainerDadosTelefone>
                      <ContainerDadosBotao>
                        <div
                          data-tip={
                            !usuario?.habilitarReenvioToken
                              ? 'Não existe nenhum Token válido para este usuário!'
                              : 'Token já solicitado. Aguarde para solicitar novamente!'
                          }
                          data-for={'tooltip-bloqueio-botao-renovar-token'}
                        >
                          <Botao
                            type="button"
                            texto="Reenviar Token"
                            tema="Secundario"
                            disabled={
                              botaoTokenBloqueado ||
                              !usuario?.habilitarReenvioToken
                            }
                            onClick={renovarToken}
                          />
                        </div>
                        {(botaoTokenBloqueado ||
                          !usuario?.habilitarReenvioToken) && (
                          <Tooltip
                            id={'tooltip-bloqueio-botao-renovar-token'}
                            place="top"
                          />
                        )}
                      </ContainerDadosBotao>
                    </ContainerDados>
                  </Secao>
                  <Secao id={Navegacoes.DadosAcesso}>
                    <SubTitulo texto="Dados de Acesso" />
                    <SelectUnform
                      multiplo
                      id="slct_perfil"
                      name="perfis"
                      label="Perfil"
                      placeholder="Selecione"
                      obrigatorio
                      opcoes={[
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Academico],
                          texto: 'Acadêmico'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Aluno],
                          texto: 'Aluno'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Comercial],
                          texto: 'Comercial'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Financeiro],
                          texto: 'Financeiro'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Gerencial],
                          texto: 'Gerencial'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Professor],
                          texto: 'Professor'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.Parceiro],
                          texto: 'Parceiro'
                        },
                        {
                          id:
                            UsuarioPerfil[UsuarioPerfil.ResponsavelFinanceiro],
                          texto: 'Responsável Financeiro'
                        },
                        {
                          id: UsuarioPerfil[UsuarioPerfil.ParceiroCertificacao],
                          texto: 'Parceiro Certificação'
                        }
                      ]}
                      valorAlteradoMulti={atualizarPerfisEdicao}
                    />
                  </Secao>
                  {possuiPerfilParceiro && (
                    <Secao id={Navegacoes.TurmasParceiro}>
                      <SubTitulo texto="Turmas" />
                      <SelectAsyncUnform
                        className="lg"
                        id="turmas-parceiro-select"
                        name="turmasParceiro"
                        key="select-async-turmas-parceiro"
                        tamanho="S"
                        buscarPorTexto={carregarOpcoesPorTexto}
                        buscarPorId={carregarOpcoes}
                        label="Turmas com vínculo de Parceiro"
                        multiplo
                      />
                    </Secao>
                  )}
                  <ContainerAcoes>
                    <Botao
                      type="button"
                      texto="Cancelar"
                      tema="Secundario"
                      disabled={carregando}
                      onClick={() => modalRef?.current?.abrir()}
                    />
                    <Botao
                      type="submit"
                      texto="Salvar"
                      carregando={carregando}
                    />
                  </ContainerAcoes>
                </>
              </FormUnform>
            </Conteudo>
          </ContainerMain>
          <ContainerLateral>
            <Navegacao
              itens={[
                {
                  link: Navegacoes.DadosPessoais,
                  nome: 'Dados Pessoais'
                },
                {
                  link: Navegacoes.Contato,
                  nome: 'Contato'
                },
                { link: Navegacoes.DadosAcesso, nome: 'Dados de Acesso' }
              ]}
            />
          </ContainerLateral>
          <Modal
            ref={modalRef}
            backdrop
            icone={<></>}
            id="modal-confirmacao-cancelamento"
            titulo={'Deseja cancelar?'}
            acaoPrimaria={{
              titulo: 'Sim',
              tipo: 'button',
              acao: () => history.goBack()
            }}
            acaoSecundario={{
              titulo: 'Não',
              tipo: 'button',
              acao: () => modalRef?.current?.fechar()
            }}
          >
            <p>Selecione uma opção</p>
          </Modal>
        </Container>
      ) : (
        <></>
      )}
    </>
  )
}

export default withRouter(PaginaUsuario)
