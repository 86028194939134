import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Api } from 'src/servicos'
import {
  ModalidadeCurso,
  SituacaoFinanceira,
  SituacaoFinanceiraFiltro,
  SituacaoFinanceiraPorExtenso,
  SituacaoMatricula,
  StatusFinanceiro,
  TipoMatricula,
  TipoMatriculaPorExtenso,
  TipoTransferenciaAlunoPorExtenso,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'
import { SituacaoReingresso } from 'src/tipos/reingresso'

import { Botao, FuncoesDataHora } from '..'

import { BarraDeProgressoDoCurso } from '../barra-de-progresso-do-curso/barra-de-progresso-do-curso.component'
import {
  Container,
  ContainerDaBarra,
  ContainerDaBarraCompacta,
  ContainerDoLink,
  BarraDeProgressoComEstilos,
  ContainerTurma,
  ContainerDatas,
  TextoSituacao
} from './styles'
import { ItemCursoProps } from './tipos'

export const ItemCurso: React.FC<ItemCursoProps> = ({
  id,
  className,
  category,
  title,
  percent,
  main,
  overdue,
  modalidade,
  situacaoFinanceira,
  statusFinanceiro,
  situacaoMatricula,
  sigla,
  etapa,
  nomeTurma,
  tipoTransferenciaOrigem,
  tipoTransferenciaDestino,
  dataCriacao,
  dataLimite,
  recuperacaoDisponivel,
  reingressoId,
  situacaoReingressoOrigem,
  situacaoReingressoDestino,
  tipoMatricula,
  precisaEscolherModulosFlex
}) => {
  const history = useHistory()

  const aguardandoPagamentoCurso = () =>
    modalidade === ModalidadeCurso.Livre
      ? history.push(`/aluno/financeiro-livre/${id}/${modalidade}/${title}`)
      : history.push(`/aluno/financeiro/${id}/${modalidade}/${title}`)

  const resolveLinkFinalizarMatricula = (
    modalidade: ModalidadeCurso,
    sigla: string
  ) => {
    if (modalidade === ModalidadeCurso.Livre) {
      return `/matricula/curso-livre/${sigla}`
    }

    if (modalidade === ModalidadeCurso.Evento) {
      return `/matricula/evento/${sigla}`
    }

    if (reingressoId) {
      return `/reingresso/${reingressoId}`
    }

    return `/matricula/turma/${sigla}`
  }

  const paraTextoApresentacao = (situacao: number, status: string): string => {
    switch (situacao) {
      case SituacaoFinanceira.Reservado:
        return 'Aguardando Pagamento'
      case SituacaoFinanceira.ContratoRescindido:
        return 'Contrato Rescindido'
      case SituacaoFinanceira.Cancelado:
        return tipoTransferenciaOrigem
          ? `Cancelado - ${TipoTransferenciaAlunoPorExtenso[tipoTransferenciaOrigem]}`
          : 'Cancelado'
      default:
        if (
          situacao === SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente ||
          status === StatusFinanceiro.AguardandoCartaoPagamentoRecorrente
        ) {
          return 'Aguardando Pag. Recorrente'
        }
        return SituacaoFinanceira[situacao] || ''
    }
  }

  const linkDeAcesso = () => {
    if (overdue) {
      const ehMatriculaFinalizada = (): boolean => {
        if (
          situacaoMatricula === SituacaoMatricula.Finalizada &&
          (SituacaoFinanceira[situacaoFinanceira] !==
            SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente ||
            StatusFinanceiro[statusFinanceiro] !==
              StatusFinanceiro.AguardandoCartaoPagamentoRecorrente)
        ) {
          return true
        }
        return false
      }

      const finalizarMatricula = () => {
        history.push(resolveLinkFinalizarMatricula(modalidade, sigla))
      }

      const mensagemFinalizacao = () => {
        if (tipoTransferenciaDestino) {
          return `sua ${TipoTransferenciaAlunoPorExtenso[tipoTransferenciaDestino]}`
        }

        if (reingressoId) {
          return 'seu reingresso'
        }

        return 'sua matrícula'
      }

      return ehMatriculaFinalizada() ? (
        <TextoSituacao>
          {paraTextoApresentacao(
            Number(SituacaoFinanceira[situacaoFinanceira]),
            StatusFinanceiro[statusFinanceiro]
          )}
        </TextoSituacao>
      ) : etapa === 'AguardandoPagamento' ? (
        <Botao
          type="button"
          id="btn-finalizar-matricula"
          texto="Clique aqui e finalize seu pagamento"
          tema="Link"
          onClick={() => {
            aguardandoPagamentoCurso()
          }}
        />
      ) : (
        <Botao
          type="button"
          id="btn-finalizar-matricula"
          texto={`Clique aqui e finalize ${mensagemFinalizacao()}`}
          tema="Link"
          onClick={() => finalizarMatricula()}
          style={{ padding: '4px 0' }}
        />
      )
    }

    const salvarUltimoAcessoCurso = async (matriculaId: string) => {
      try {
        await Api.UltimoAcessoCurso(matriculaId)
      } catch (erro) {
        toast('Erro ao salvar último acesso ao curso.', { type: 'error' })
      }
    }

    const tipoDeModalidade = validaEhModalidadeLivreOuEvento(modalidade)
      ? 'curso-livre'
      : 'outros-cursos'
    const url = `sala-aula/${tipoDeModalidade}/${id}`

    const urlSelecaoModulosFlex = `sala-aula/selecao-modulos/${id}`

    const selecionarUrlCorreta = () => {
      if (
        modalidade === ModalidadeCurso.PosGraduacaoEadFlex &&
        precisaEscolherModulosFlex
      ) {
        return urlSelecaoModulosFlex
      }
      return url
    }

    return (
      <Link
        onClick={() => salvarUltimoAcessoCurso(id)}
        to={selecionarUrlCorreta}
      >
        Ir para o curso
      </Link>
    )
  }

  const barraDeProgresso = () => {
    return (
      !overdue &&
      main && (
        <ContainerDaBarra>
          <BarraDeProgressoComEstilos progresso={percent} />
        </ContainerDaBarra>
      )
    )
  }

  const barraDeProgressoCompacta = () => {
    return (
      !overdue &&
      !main &&
      SituacaoFinanceiraFiltro[situacaoFinanceira] !==
        SituacaoFinanceiraFiltro.Finalizado &&
      SituacaoFinanceiraFiltro[situacaoFinanceira] !==
        SituacaoFinanceiraFiltro.Reprovado && (
        <ContainerDaBarraCompacta>
          <BarraDeProgressoDoCurso progresso={percent} />
        </ContainerDaBarraCompacta>
      )
    )
  }

  const situacaoFinanceiraAluno = () => {
    return (
      (SituacaoFinanceiraFiltro[situacaoFinanceira] ===
        SituacaoFinanceiraFiltro.Finalizado ||
        SituacaoFinanceiraFiltro[situacaoFinanceira] ===
          SituacaoFinanceiraFiltro.Reprovado ||
        SituacaoFinanceiraFiltro[situacaoFinanceira] ===
          SituacaoFinanceiraFiltro.Recuperacao) && (
        <TextoSituacao>
          {SituacaoFinanceiraPorExtenso[situacaoFinanceira]}
        </TextoSituacao>
      )
    )
  }

  const reingresso = () => {
    const validacao =
      Number(TipoMatricula[tipoMatricula]) === TipoMatricula.Reingresso &&
      situacaoReingressoDestino === SituacaoReingresso.Finalizado

    return (
      validacao && (
        <TextoSituacao>{TipoMatriculaPorExtenso[tipoMatricula]}</TextoSituacao>
      )
    )
  }

  return (
    <Container className={className} main={main} overdue={overdue}>
      {barraDeProgresso()}
      <div className="conteudo">
        <h4>{category}</h4>
        <h5>{title}</h5>
        {nomeTurma && (
          <ContainerTurma>
            <h6>Turma</h6>
            <h6>{nomeTurma}</h6>
          </ContainerTurma>
        )}
        {modalidade === ModalidadeCurso.Livre && (
          <ContainerDatas>
            <p>
              <b>Data Início: </b> {FuncoesDataHora.novaData(dataCriacao)}
            </p>
            <p>
              <b>Data Limite: </b> {FuncoesDataHora.novaData(dataLimite)}
            </p>
          </ContainerDatas>
        )}
        <ContainerDoLink>
          {linkDeAcesso()}
          {situacaoFinanceiraAluno()}
          {reingresso()}
          {barraDeProgressoCompacta()}
        </ContainerDoLink>
        {SituacaoFinanceira[situacaoFinanceira] ===
          Number(SituacaoFinanceira.Reprovado) &&
          recuperacaoDisponivel && (
            <ContainerDoLink>
              <Link to={`/sala-aula/outros-cursos/${id}/recuperacao`}>
                Clique aqui e contrate recuperação
              </Link>
            </ContainerDoLink>
            // eslint-disable-next-line indent
          )}
        {SituacaoFinanceira[situacaoFinanceira] ===
          Number(SituacaoFinanceira.Reprovado) &&
          !recuperacaoDisponivel &&
          reingressoId &&
          situacaoReingressoOrigem !== SituacaoReingresso.Finalizado &&
          situacaoReingressoOrigem !== SituacaoReingresso.Cancelado &&
          situacaoReingressoOrigem !==
            SituacaoReingresso.SemTurmaReingresso && (
            <ContainerDoLink>
              <Link to={`/reingresso/${reingressoId}`}>
                Clique aqui e realize seu reingresso
              </Link>
            </ContainerDoLink>
            // eslint-disable-next-line indent
          )}
      </div>
    </Container>
  )
}
