export enum StatusFinanceiro {
  Inadimplente = 'Inadimplente',
  EmDia = 'EmDia',
  Quitado = 'Quitado',
  AguardandoCartaoPagamentoRecorrente = 'AguardandoCartaoPagamentoRecorrente',
  Adimplente = 'Adimplente'
}

export enum StatusFinanceiroPorExtenso {
  Inadimplente = 'Inadimplente',
  EmDia = 'Em dia',
  Quitado = 'Quitado',
  AguardandoCartaoPagamentoRecorrente = 'Aguardando Cartão Pagamento Recorrente',
  Adimplente = 'Adimplente'
}
