import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { IconeDoAvatarDoUsuario, NotificacoesContainer } from 'src/componentes'
import { RotasAcademico, RotasPrecificacao } from 'src/rotas'
import { RotasAluno } from 'src/rotas/aluno'
import { RotasProfessor } from 'src/rotas/professor'
import { RotasResponsavelFinanceiro } from 'src/rotas/responsavel-financeiro'
import { Api } from 'src/servicos'

import { Visao } from '../autenticacao'
import AutenticacaoContainer from '../autenticacao/container'
import { opcoesDeVisao } from '../autenticacao/funcoes'
import { Botao } from '../botao'
import { useResponsivoState } from '../contextos/responsivo'
import { useTema } from '../contextos/tema'
import { dataFormatadaComBarras } from '../funcoes/data-hora'
import { IconeNotificacoes, IconeGrupoDiscussao } from '../icones'
import { Logo, Hamburguer as HamburguerImagem } from '../imagens'
import { ItemNotificacao } from '../item-notificacao'
import { ListaSuspensa } from '../lista-suspensa'
import { MenuContexto, MenuContextoRef } from '../menu-contexto'
import { useBarraSuperior } from './hooks'
import {
  Container,
  Barra,
  Hamburguer,
  Itens,
  Usuario,
  Item,
  Menu,
  InfosLogin,
  InfosNotificacoes,
  Notificacoes,
  SemNotificacoes
} from './styles'

export const BarraSuperiorLayout: React.FC = () => {
  const {
    perfil,
    usuario,
    sair,
    alterarVisao,
    visao
  } = AutenticacaoContainer.useContainer()

  const { mobile, tablet, desktop } = useResponsivoState()
  const [, temaDispatch] = useTema()
  const history = useHistory()
  const barraSuperior = useBarraSuperior()
  const { notificacoes, marcarComoLida } = NotificacoesContainer.useContainer()
  const menuRef = useRef<MenuContextoRef>(null)
  const [matriculas, setMatriculas] = useState([])
  const [turmaCertificadora, setTurmaCertificadora] = useState<boolean>()

  const obterAvatar = () => {
    const avatar = barraSuperior.obterFoto()

    return !avatar ? (
      IconeDoAvatarDoUsuario
    ) : (
      <img src={avatar} alt={usuario.nomeApresentacao} />
    )
  }

  const fecharMenu = () => menuRef.current?.fechar()

  const procurarPerfilUsuario = (perfilUsuario: string) => {
    if (Array.isArray(perfil.perfis)) {
      return perfil?.perfis?.find(perfils => perfils === perfilUsuario)
    } else {
      return perfilUsuario === perfil.perfis
    }
  }
  const visaoAluno = useMemo(() => visao === Visao.Aluno, [visao])
  const visaoProfessor = useMemo(() => visao === Visao.Professor, [visao])
  const visaoResponsavel = useMemo(
    () => visao === Visao.ResponsavelFinanceiro,
    [visao]
  )

  const perfilEVisaoResponsavelFinanceiro = useMemo(() => {
    return procurarPerfilUsuario('ResponsavelFinanceiro') && visaoResponsavel
  }, [visaoResponsavel, perfil])

  useEffect(() => {
    if (desktop) temaDispatch({ tipo: 'retrair-menu' })
  }, [desktop])

  useEffect(() => {
    return history.listen(() => fecharMenu())
  }, [history])

  const obterPainelInicialDoAluno = async (idDoUsuario: string) => {
    if (perfil.perfis.includes('Aluno')) {
      try {
        const resposta = await Api.ObterPainelInicialDoAluno(idDoUsuario)
        setMatriculas(resposta.painelAluno.matriculas)
      } catch (erro) {
        console.error('Algo deu errado, tente novamente mais tarde.')
      }
    }
  }

  useEffect(() => {
    if (perfil.perfis.includes('Aluno')) {
      obterPainelInicialDoAluno(usuario.id)
    }
  }, [])

  useEffect(() => {
    if (perfil.perfis.includes('Aluno')) {
      if (matriculas && Array.isArray(matriculas) && matriculas.length > 0) {
        const certificadoraMatricula = matriculas.find(
          m => m.turma && m.turma.turmaCertificadora === true
        )

        const certificadora = certificadoraMatricula
          ? certificadoraMatricula.turma &&
            certificadoraMatricula.turma.turmaCertificadora
          : false

        setTurmaCertificadora(certificadora)
      }
    }
  }, [matriculas])

  return (
    <Container>
      <Hamburguer
        onClick={() => {
          if (mobile || tablet) {
            temaDispatch({ tipo: 'expandir-retrair-menu' })
          }
        }}
      >
        {perfil ? HamburguerImagem : <></>}
      </Hamburguer>
      <Barra>
        <Link className="logo-ipgs" to="/">
          {Logo(desktop ? 'Padrao' : 'Secundario')}
        </Link>
        <Itens>
          {perfil ? (
            <>
              {turmaCertificadora === false ? (
                <Notificacoes>
                  <MenuContexto
                    ref={menuRef}
                    botao={
                      <Item
                        onClick={() => {
                          history.push('/sistema/FAQ')
                        }}
                      >
                        {IconeGrupoDiscussao}
                      </Item>
                    }
                  />
                </Notificacoes>
              ) : (
                <></>
              )}
              <Notificacoes>
                <MenuContexto
                  ref={menuRef}
                  botao={
                    <Item>
                      <IconeNotificacoes
                        badge={notificacoes.some(
                          notificacao => !notificacao.lida
                        )}
                      />
                    </Item>
                  }
                  posicao="direita"
                  abrirComClick
                >
                  <Menu>
                    <InfosNotificacoes>
                      <h3>Notificações</h3>
                      <ul>
                        {(notificacoes.length > 0 &&
                          notificacoes.map(
                            (
                              { titulo, data, descricao, lida, url },
                              indice
                            ) => (
                              <ItemNotificacao
                                key={indice}
                                title={titulo}
                                date={dataFormatadaComBarras(data)}
                                description={descricao}
                                unread={!lida}
                                redirecionamento={url}
                                idUsuario={usuario.id}
                                marcarComoLida={() => marcarComoLida(titulo)}
                              />
                            )
                          )) || (
                          <SemNotificacoes>
                            Não existem notificações.
                          </SemNotificacoes>
                        )}
                      </ul>
                      <Botao type="button" onClick={() => sair()} tema="Link">
                        Sair
                      </Botao>
                    </InfosNotificacoes>
                  </Menu>
                </MenuContexto>
              </Notificacoes>
              <Usuario>
                <MenuContexto
                  ref={menuRef}
                  botao={
                    <Item>
                      <figure>
                        {obterAvatar()}
                        <figcaption>{usuario.nomeApresentacao}</figcaption>
                      </figure>
                    </Item>
                  }
                  posicao="direita"
                  abrirComClick
                >
                  <Menu>
                    <InfosLogin>
                      <label>Olá, você está logado como:</label>
                      <ListaSuspensa
                        tipo="radio"
                        itens={opcoesDeVisao(perfil?.perfis ?? []).map(
                          p => Visao[p]
                        )}
                        valores={[Visao[visao]]}
                        valorAlterado={(itensMarcados: string[]) => {
                          alterarVisao(Visao[itensMarcados[0]])
                          switch (Visao[itensMarcados[0]]) {
                            case Visao.IPGS:
                            case Visao.Parceiro:
                              history.push(RotasAcademico.Dashboard)
                              break
                            case Visao.Aluno:
                              history.push(RotasAluno.Dashboard)
                              break
                            case Visao.Professor:
                              history.push(RotasProfessor.Dashboard)
                              break
                            case Visao.ResponsavelFinanceiro:
                              history.push(RotasResponsavelFinanceiro.Dashboard)
                              break
                          }
                        }}
                        posicao="direita"
                        renderizarItem={valor => <p>{valor}</p>}
                        renderizarBotao={valor => <p>{valor}</p>}
                        abrirComClick
                      />
                      {procurarPerfilUsuario('Aluno') && visaoAluno ? (
                        <>
                          <Botao
                            type="button"
                            onClick={() => {
                              history.push(RotasAluno.Edicao)
                            }}
                            tema="Link"
                            texto="Meu Cadastro"
                            className="btn-itens"
                          />
                        </>
                      ) : null}
                      {procurarPerfilUsuario('Professor') && visaoProfessor && (
                        <>
                          <Botao
                            type="button"
                            onClick={() => {
                              history.push(RotasProfessor.MeuCadastro)
                            }}
                            tema="Link"
                            texto="Meu Cadastro"
                            className="btn-itens"
                          />
                        </>
                      )}
                      {perfilEVisaoResponsavelFinanceiro && (
                        <>
                          <Botao
                            type="button"
                            onClick={() => {
                              history.push(
                                RotasResponsavelFinanceiro.MeuCadastro
                              )
                            }}
                            tema="Link"
                            texto="Meu Cadastro"
                            className="btn-itens"
                          />
                        </>
                      )}
                      <Botao
                        type="button"
                        onClick={() => sair()}
                        tema="Link"
                        texto="Sair"
                        className="btn-sair"
                      />
                    </InfosLogin>
                  </Menu>
                </MenuContexto>
              </Usuario>
            </>
          ) : (
            <>
              <Link to="/matricula">Cadastrar</Link>
              <Link to="/matricula">Entrar</Link>
            </>
          )}
        </Itens>
      </Barra>
    </Container>
  )
}
