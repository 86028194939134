import React, { FC, useEffect, useMemo } from 'react'

import { useControladorDeConteudos } from 'src/compartilhados'
import {
  IconeBook,
  ItemCurso,
  LinkRodape,
  ItemCarrinhoCursoLivre,
  Spacer
} from 'src/componentes'
import { somarDias } from 'src/paginas/sala-aula/shared/funcoes/calcularDatas'
import {
  MatriculaDoPainelDoAluno,
  ModalidadeCurso,
  SituacaoFinanceira,
  ModalidadeCursoPorExtenso,
  CarrinhoCompraDoPainelDoAluno,
  validaEhModalidadeLivreOuEvento
} from 'src/tipos'

import { CursosProps } from './cursos.props'
import { controleDeDisposicao } from './hooks'
import {
  ContainerCursos,
  ListaCursos,
  SubTituloContainer,
  SubTituloIcone,
  SubTitulo,
  CursosNaoEncontrados,
  RequisicaoComErros,
  CarregandoCursos,
  LinkRodapeContainer
} from './styles'

const disposicao = {
  disposicaoInicial: 4,
  passos: 3
}

export const Cursos: FC<CursosProps> = ({
  controleDeRequisicao,
  acaoAtualizacao
}: CursosProps) => {
  const { carregador, erros, resposta } = controleDeRequisicao

  const { matriculas, carrinhosCompra } = resposta.obterResposta()
  const configuracao = controleDeDisposicao(matriculas, disposicao)

  const controlador = useControladorDeConteudos(matriculas, configuracao)
  const controladorCarrinho = useControladorDeConteudos(carrinhosCompra, {
    disposicaoInicial: disposicao.disposicaoInicial,
    passos: disposicao.passos
  })

  const possuiCarrinho = useMemo(() => {
    const { carrinhosCompra } = resposta.obterResposta()
    return carrinhosCompra && carrinhosCompra?.length > 0
  }, [resposta])

  useEffect(() => {
    const { matriculas, carrinhosCompra } = resposta.obterResposta()
    const configuracao = controleDeDisposicao(matriculas, disposicao)

    controlador.atualizar(matriculas, configuracao.passos)
    controladorCarrinho.atualizar(carrinhosCompra, disposicao.passos)
  }, [resposta])

  if (carregador.estaInativo()) {
    return <ContainerCursos />
  }

  if (carregador.foiIniciado()) {
    return (
      <ContainerCursos>
        <CarregandoCursos children={'Carregando cursos...'} />
      </ContainerCursos>
    )
  }

  if (carregador.foiConcluido()) {
    if (erros.existemErros() || !resposta.possuiResposta()) {
      return (
        <ContainerCursos>
          <RequisicaoComErros
            children={'Ops! Aconteceu um erro. Tente novamente.'}
          />
        </ContainerCursos>
      )
    }
  }

  if (matriculas.length === 0 && !possuiCarrinho) {
    return (
      <ContainerCursos>
        <CursosNaoEncontrados
          children={'Você ainda não está matriculado em nenhum curso.'}
        />
      </ContainerCursos>
    )
  }

  const matriculaEstaEmAtraso = (situacaoFinanceira: string) =>
    Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.Recuperacao &&
    Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.Matriculado &&
    Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.Finalizado &&
    Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.Reprovado &&
    Number(SituacaoFinanceira[situacaoFinanceira]) !==
      SituacaoFinanceira.AguardandoCartaoPagamentoRecorrente

  const matriculaPossuiPosGraduacao = (modalidade: ModalidadeCurso) => {
    return new Array<boolean>(
      modalidade === ModalidadeCurso.PosGraduacaoEad,
      modalidade === ModalidadeCurso.PosGraduacaoEadFull,
      modalidade === ModalidadeCurso.PosGraduacaoEadFlex,
      modalidade === ModalidadeCurso.PosGraduacaoPresencial,
      modalidade === ModalidadeCurso.PosGraduacaoEadFullIntegracaoInstitucional,
      modalidade === ModalidadeCurso.PosGraduacaoEadIntegracaoInstitucional
    ).some(condicao => condicao)
  }

  const listarCursos = () => {
    const mapper = (matricula: MatriculaDoPainelDoAluno) => {
      const posGraduacao = matriculaPossuiPosGraduacao(
        matricula.curso.modalidade
      )
      const ehMBA =
        matricula.curso.modalidade === ModalidadeCurso.MBAEad ||
        matricula.curso.modalidade === ModalidadeCurso.MBAEadFull
      const nomeDaClasse = posGraduacao || ehMBA ? 'postgraduate' : 'freecourse'
      let nomeDaCategoria: string
      if (ehMBA) {
        nomeDaCategoria = 'MBA'
      } else if (posGraduacao) {
        nomeDaCategoria = 'Pós-graduação'
      } else {
        nomeDaCategoria = ModalidadeCursoPorExtenso[matricula.curso.modalidade]
      }
      const destaque = Boolean(posGraduacao || ehMBA)
      const progresso = matricula.progresso || 0
      const atraso = matriculaEstaEmAtraso(matricula.situacaoFinanceira)
      const dataLimite = somarDias(
        matricula.dataCriacao,
        matricula?.curso?.tempoDisponibilidade
      )

      const ehModalidadeLivreOuEvento = validaEhModalidadeLivreOuEvento(
        matricula?.curso?.modalidade
      )

      return carregador.foiConcluido() ? (
        <ItemCurso
          id={matricula.id}
          key={matricula.id}
          className={nomeDaClasse}
          category={nomeDaCategoria}
          title={matricula.curso.nome}
          percent={progresso}
          main={destaque}
          overdue={atraso}
          modalidade={matricula.curso.modalidade}
          situacaoFinanceira={matricula.situacaoFinanceira}
          situacaoMatricula={matricula.situacaoMatricula}
          statusFinanceiro={matricula.statusFinanceiro}
          nomeTurma={matricula?.turma?.nome}
          sigla={
            ehModalidadeLivreOuEvento
              ? matricula.curso.sigla
              : matricula.turma.codigo
          }
          etapa={matricula.etapa}
          tipoTransferenciaOrigem={
            matricula.transferenciaOrigem?.tipoTransferencia
          }
          tipoTransferenciaDestino={
            matricula.transferenciaDestino?.tipoTransferencia
          }
          dataCriacao={matricula.dataCriacao}
          dataLimite={dataLimite}
          acaoAtualizar={acaoAtualizacao}
          acaoLoad={carregador.alterarParaIniciado}
          recuperacaoDisponivel={matricula.recuperacaoDisponivel}
          reingressoId={
            matricula.reingressoOrigem?.id ?? matricula.reingressoDestino?.id
          }
          situacaoReingressoOrigem={
            matricula.reingressoOrigem?.situacaoReingresso
          }
          situacaoReingressoDestino={
            matricula.reingressoDestino?.situacaoReingresso
          }
          tipoMatricula={matricula.tipo}
          precisaEscolherModulosFlex={matricula?.precisaEscolherModulosFlex}
        />
      ) : (
        <>
          <ContainerCursos>
            <CarregandoCursos children={'Carregando cursos...'} />
          </ContainerCursos>
        </>
      )
    }

    const children = controlador.exibir().map(mapper)

    return <ListaCursos children={children} />
  }

  const listarCarrinhoCompra = () => {
    const mapper = (carrinho: CarrinhoCompraDoPainelDoAluno) => {
      const nomeDaClasse = 'postgraduate'
      const nomeDaCategoria = 'Carrinho Compra Curso'

      const destaque = false

      return carregador.foiConcluido() ? (
        <ItemCarrinhoCursoLivre
          id={carrinho.carrinhoCompraId}
          key={carrinho.carrinhoCompraId}
          className={nomeDaClasse}
          category={nomeDaCategoria}
          title={'Carrinho Compra'}
          main={destaque}
          statusCarrinhoCompra={carrinho.statusCarrinho}
          siglasCursos={carrinho.matriculas.map(x => x.curso.sigla)}
          aguardandoPagamento={carrinho.aguardandoPagamento}
        />
      ) : (
        <>
          <ContainerCursos>
            <CarregandoCursos children={'Carregando carrinho de compra...'} />
          </ContainerCursos>
        </>
      )
    }

    const children = controladorCarrinho.exibir().map(mapper)

    return <ListaCursos children={children} />
  }
  const botaoVerMais = () => {
    if (controlador.concluido()) {
      return null
    }

    const execucao = () => controlador.proximo()
    return (
      <LinkRodapeContainer>
        <LinkRodape acao={execucao} texto="Ver mais" />
      </LinkRodapeContainer>
    )
  }

  return (
    <ContainerCursos>
      {possuiCarrinho && (
        <>
          <SubTituloContainer>
            <SubTituloIcone>{IconeBook}</SubTituloIcone>
            <SubTitulo>Meus pedidos em andamento</SubTitulo>
          </SubTituloContainer>
          {listarCarrinhoCompra()}
          <Spacer padding="20px 0px" />
        </>
      )}
      {matriculas?.length > 0 && (
        <>
          <SubTituloContainer>
            <SubTituloIcone>{IconeBook}</SubTituloIcone>
            <SubTitulo>Meus cursos em andamento</SubTitulo>
          </SubTituloContainer>
          {listarCursos()}
          {botaoVerMais()}
        </>
      )}
    </ContainerCursos>
  )
}
